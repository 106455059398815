<template>
  <div class="home">
    <Banner
      text="Our digital agency was built for simple and optimal promotion of your business"
      withBtn
    >
      <template v-slot:key>Easier</template>
      <template v-slot:row>than</template>
      <template v-slot:other>never before</template>
    </Banner>
    <About ref="about"/>
    <MissionVision ref="mission" />
    <Features ref="features" />
    <Services ref="services" />
    <Feedback />
    <Footer />
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, watch, nextTick } from "vue";
import Banner from "@/components/sections/Banner.vue";
import About from "@/components/sections/About.vue";
import MissionVision from "@/components/sections/MissionVision.vue";
import Features from "@/components/sections/Features.vue";
import Services from "@/components/sections/Services.vue";
import Feedback from "@/components/sections/Feedback.vue";
import Footer from "@/components/base/Footer.vue";
import {useScrollStore} from "@/store/scroll";

const navigationStore = useScrollStore();
const about = ref(null);
const mission = ref(null);
const features = ref(null);
const services = ref(null);

watch(() => navigationStore.blockIndex, (newValue, oldValue) => {
  if (navigationStore.blockClicked) {
    nextTick(() => {
      scrollToBlock(newValue);
    });
  }
}, {deep: true})

const getBlockRef = (index) => {
  switch(index) {
    case 'about':
      return about.value;
    case 'mission':
      return mission.value;
    case 'features':
      return features.value;
    case 'services':
      return services.value;
  }
}

const scrollToBlock = (blockIndex) => {
  if(blockIndex !== 'products') {
    const blockRef = getBlockRef(blockIndex);
    if (blockRef && blockRef.$el) {
      blockRef.$el.scrollIntoView({ behavior: 'smooth' });
    }
  }
}

onMounted(() => {
  if(navigationStore.blockIndex === 0) navigationStore.setBlockIndex('home');
  if (navigationStore.blockClicked) {
    setTimeout(() => {
      scrollToBlock(navigationStore.blockIndex)
    }, 0);
  }
})

// onBeforeUnmount(() => {
//   navigationStore.setBlockIndex(0);
//   navigationStore.setBlockClicked(false);
// })

</script>
