<script setup>
import {ref} from "vue";
import { useRouter, useRoute } from 'vue-router';
import Logo from "@/components/base/Logo.vue";
import GoTop from "@/components/base/GoTop.vue";
import BlockText from "@/components/additional/BlockText.vue";
import {useScrollStore} from "@/store/scroll";

const navStore = useScrollStore();
const router = useRouter();
const route = useRoute();

const anchors = ref([
  {
    label: "About us",
    block: "about"
  },
  {
    label: "Mission & Vision",
    block: "mission"
  },
  {
    label: "Features",
    block: "features"
  },
  {
    label: "Services",
    block: "services"
  }
])

const services = ref([
  {
    label: "Marketing Strategies",
    link: "marketing-strategy"
  },
  {
    label: "360º Marketing",
    link: "marketing"
  },
  {
    label: "Creative & Development",
    link: "creative-development"
  }
])

const legal = ref([
  {
    label: "Privacy policy",
    link: "/privacy"
  }
])

const contacts = ref([
  {
    label: "+357 999 42 186"
  },
  {
    label: "info@dragomine.agency",
    href: "mailto:info@dragomine.agency",
  },
  {
    label: "Dragomine limited HE 389734 Christodoulou Hadjipavlou Avenue 199, 3026, Limassol, Cyprus"
  }
])

const follow = ref([
  {
    label: "Linkedin",
    href: "https://www.linkedin.com/company/dragomine/"
  }
])

const scrollToBlock = (i) => {
  if(route.path !== '/') router.push('/');
  navStore.setBlockClicked(true);
  navStore.setBlockIndex(anchors.value[i].block);
}

</script>

<template>
  <footer>
    <div class="container">
      <div class="footer-content flex justify-between">
        <div class="left flex justify-between">
          <logo animated />
          <div class="footer-nav flex flex-col color-gray-50">
            <p
              v-for="(nav, index) in anchors"
              :key="index"
              v-motion
              :initial="{opacity: 0, y: -10}"
              :visibleOnce="{opacity: 1, y: 0, transition: { duration: 700, delay: index*100+100 }}"
              @click="scrollToBlock(index)"
            >{{ nav.label }}</p>
          </div>
          <GoTop />
        </div>
        <div class="right flex flex-col">
          <div class="right__part flex justify-between">
            <BlockText
              title="Services"
              :items="services"
            ></BlockText>
            <BlockText
              title="Legal"
              :items="legal"
            ></BlockText>
          </div>
          <div class="right__part flex justify-between">
            <BlockText
              title="Contact us"
              :items="contacts.slice(0,2)"
            ></BlockText>
            <BlockText
              title="&nbsp;"
              :items="contacts.slice(2)"
            ></BlockText>
          </div>
          <div class="right__part">
            <BlockText
              title="Follow us"
              :items="follow"
              blank
            ></BlockText>
          </div>
          <div class="right__part mobile">
            <BlockText
              title="Legal"
              :items="legal"
            ></BlockText>
          </div>
          <p class="copyright" v-motion :initial="{opacity: 0}" :visibleOnce="{opacity: 1, transition: { duration: 700, delay: 500 }}">© 2024 — Dragomine limited. All Rights Reserved</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss">
footer {
  background: #141414;
  padding: 50px 0 36px;
}
.footer-nav {
  gap: 4px;
  p {
    cursor: pointer;
    transition: all .3s ease;
    &:hover {
      color: $light-text;
    }
    &:active {
      color: #626260;
    }
  }
}
.footer-content {
  padding-right: 35px;
  .left {
    position: relative;
    flex: 0 0 35%;
    & > div:last-child {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .right {
    gap: 76px;
    flex: 0 0 50%;
    &__part {
      &.mobile {
        display: none;
      }
      width: 100%;
    }
  }
  .copyright {
    color: #72716D;
    font-size: 10px;
    line-height: 20px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    margin-top: 96px;
  }
}

@media (max-width: 1024px) {
  .footer-content {
    .left {
      flex: 0 0 45%;
    }
    .right {
      flex: 0 0 40%;
      &__part {
        flex-direction: column;
        gap: 30px;
      }
    }
    .copyright {
      margin-top: 0;
    }
  }
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    padding-right: 0;
    gap: 46px;
    p {
      line-height: 20px;
    }
    .left {
      flex-direction: column;
      justify-content: flex-start;
      gap: 40px;
      flex: 0 0 100%;
      & > div:last-child {
        position: absolute;
        top: 0;
        bottom: auto;
        left: auto;
        right: 0;
      }
    }
    .right {
      gap: 46px;
      & > div:first-child > div:last-child {
        display: none;
      }
    }
    .right__part:nth-child(2) {
      gap: 0;
      .block:last-child p {
        margin-bottom: 0 !important;
      }
    }
    .right__part.mobile {
      display: block;
    }
  }
  footer {
    padding: 40px 0 28px;
  }
  .footer-nav {
    gap: 12px;
  }
  .copyright {
    margin-top: 8px;
  }
}
</style>