<script setup>

</script>

<template>
  <section class="s-about" ref="about">
    <div class="container">
      <h4 class="gradient-text" v-motion :initial="{opacity: 0, scale: 2.2, rotate: -15}" :visibleOnce="{opacity: 1, scale: 1, rotate: 0, transition: { duration: 700, delay: 100 }}">About us</h4>
      <div class="s-about__content flex justify-between align-center">
        <div class="s-about__left">
          <h2 class="light-text" v-motion :initial="{opacity: 0, y: -10}" :visibleOnce="{opacity: 1, y: 0, transition: { duration: 600, delay: 200 }}">How we work</h2>
          <h5 class="transparency-text" v-motion :initial="{opacity: 0, y: -10}" :visibleOnce="{opacity: 1, y: 0, transition: { duration: 700, delay: 300 }}">We offer tailored solutions for every challenge</h5>
          <p class="transparency-text" v-motion :initial="{opacity: 0, y: -10}" :visibleOnce="{opacity: 1, y: 0, transition: { duration: 900, delay: 300 }}">Our team comprises highly experienced professionals that can advise you on strategy, project design/development as well as select the best tools for revenue marketing. We elaborate customized result-centered solutions to propel maximum growth.</p>
        </div>
        <div class="s-about__right" v-motion :initial="{opacity: 0}" :visibleOnce="{opacity: 1, transition: { duration: 700, delay: 500 }}">
          <img src="@/assets/img/about.png" alt="dragomine about section picture" />
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.s-about {
  padding: 100px 0 0;
  h5 {
    margin: 31.5px 0 40px;
    width: 95%;
  }
  &__left {
    width: 43%;
    p:last-child {
      padding-bottom: 80px;
    }
  }
  &__right {
    width: 55%;
    max-width: 557px;
    img {
      width: 100%;
    }
  }
}

@media (max-width: 1280px) {
  .s-about {
    overflow: hidden;
    &__content {
      margin-top: 20px;
    }
    &__left {
      width: 55%;
    }
    &__right {
      width: 45%;
      margin-right: -6%;
    }
  }
}

@media (max-width: 1024px) {
  .s-about__right {
    width: 55%;
    margin-right: -10%;
  }
}

@media (max-width: 768px) {
  .s-about {
    h5 {
      margin: 24px 0;
    }
    &__content {
      flex-direction: column-reverse;
      margin-top: 30px;
      gap: 30px;
    }
    &__left p:last-child {
      padding-bottom: 60px;
    }
    &__left, &__right {
      width: 100%;
    }
    &__right {
      margin-right: 0;
    }
  }
}
</style>