<script setup>
import { ref } from "vue";
import BlockItem from "@/components/additional/BlockItem.vue";

const blocks = ref([
  {
    title: "Our mission is to empower businesses ",
    text: "To achieve their fullest potential through innovative, " +
        "data-driven marketing solutions. We are committed to delivering " +
        "exceptional results by understanding our clients' unique needs, " +
        "fostering meaningful relationships, and consistently exceeding expectations. ",
    image: "block1.png",
    position: "left"
  },
  {
    title: "Our vision is to become the leading marketing agency",
    text: "Recognized for transforming brands and driving sustainable growth. " +
        "We aspire to set the standard for excellence in the marketing industry " +
        "by leveraging cutting-edge technologies, embracing diversity of thought, " +
        "and fostering a culture of collaboration and innovation. ",
    image: "block2.png",
    position: "right"
  },
])
</script>

<template>
  <section class="mission-vision" ref="mission">
    <div class="container">
      <h4 class="gradient-text" v-motion :initial="{opacity: 0, scale: 2.2, rotate: -15}" :visibleOnce="{opacity: 1, scale: 1, rotate: 0, transition: { duration: 700, delay: 100 }}">Mission & Vision</h4>
    </div>
    <div class="mission-vision__content flex flex-col">
      <BlockItem
        v-for="item in blocks"
        :key="item.title"
        :title="item.title"
        :text="item.text"
        :image="item.image"
        :position="item.position"
      />
    </div>
  </section>
</template>

<style scoped lang="scss">
.mission-vision {
  padding: 70px 0 100px;
  overflow: hidden;
  &__content {
    margin-top: 34px;
    padding: 0 12px;
    gap: 12px;
  }
}

@media (max-width: 768px) {
  .mission-vision {
    padding: 0 0 59px;
    &__content {
      gap: 14px;
    }
  }
}
</style>