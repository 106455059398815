<script setup>
const goToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}
</script>

<template>
  <div class="go-top flex align-center justify-center" @click="goToTop" v-motion :initial="{opacity: 0}" :visibleOnce="{opacity: 1, transition: { duration: 700, delay: 300 }}">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 136" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M60 0L120 60.0284L111.549 68.4836L79.634 36.5536C74.5949 31.512 65.9758 35.0809 65.9758 42.2091V136H54.0242V22.8889L8.45111 68.4836L0 60.0284L60 0Z" fill="#CAC9C4"/>
    </svg>
  </div>
</template>

<style scoped lang="scss">
.go-top {
  border-radius: 340px;
  border: 1px solid $gray-400;
  cursor: pointer;
  transition: all .3s ease;
  background: none;
  font-size: 340px;
  width: 1em;
  height: 1em;
  svg {
    width: 120px;
    height: 136px;
    path {
      transition: all .3s ease;
    }
  }

  &:hover {
    border: 1px solid $gray-200;
    background: rgba(245, 245, 247, 0.07);
    svg path {
      fill: $light-text;
    }
  }

  &:focus,
  &:active {
    border: 1px solid $light-text;
    svg path {
      fill: $gray-300;
    }
  }
}

@media (max-width: 768px) {
  .go-top {
    font-size: 112px;
    svg {
      width: 90px;
      height: 50px;
    }
  }
}
</style>