<script setup>
import { ref, watch } from "vue";
import Logo from "@/components/base/Logo.vue";
import Menu from "@/components/base/Menu.vue";

const isMenu = ref(false);

watch(() => isMenu.value, () => {
  if(isMenu.value) document.body.classList.add('no-scroll');
  else document.body.classList.remove('no-scroll');
})


</script>

<template>
  <header class="flex align-center justify-between">
    <Logo />
    <div class="burger flex align-center justify-center" @click="isMenu = true">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M14 15C14 15.5523 14.4477 16 15 16C15.5523 16 16 15.5523 16 15C16 14.4477 15.5523 14 15 14C14.4477 14 14 14.4477 14 15Z" stroke="#F5F5F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8 15C8 15.5523 8.44772 16 9 16C9.55228 16 10 15.5523 10 15C10 14.4477 9.55228 14 9 14C8.44772 14 8 14.4477 8 15Z" stroke="#F5F5F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14 9C14 9.55228 14.4477 10 15 10C15.5523 10 16 9.55228 16 9C16 8.44772 15.5523 8 15 8C14.4477 8 14 8.44772 14 9Z" stroke="#F5F5F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8 9C8 9.55228 8.44772 10 9 10C9.55228 10 10 9.55228 10 9C10 8.44772 9.55228 8 9 8C8.44772 8 8 8.44772 8 9Z" stroke="#F5F5F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <Menu :isOpen="isMenu" @close="isMenu = false"></Menu>
  </header>
</template>

<style lang="scss">
header {
  height: 90px;
  width: 100%;
  padding: 0 20px;
  .burger {
    border-radius: 10px;
    border: 1px solid #F5F5F7;
    width: 40px;
    min-width: 40px;
    height: 40px;
  }
}
</style>