<script setup>
import { ref } from "vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import SliderControl from "@/components/additional/SliderControl.vue";
import FeatureItem from "@/components/additional/FeatureItem.vue";
import 'swiper/css';

const items = ref([
  {
    title: "Initial consultation",
    text: "Build trust in our expertise and ability to support you on your journey.",
    background: "feature1.jpg",
  },
  {
    title: "Analyze the current state of your project",
    text: "Launching a successful project starts with a clear understanding of its current state.",
    background: "feature2.jpg",
  },
  {
    title: "Propose personalized solutions",
    text: "Your goals are unique. That's why we offer personalized solutions tailored to your specific needs.",
    background: "feature3.jpg",
  }
])

const swiperInstance = ref(null);
const onSwiper = (swiper) => {
  swiperInstance.value = swiper;
};

const goNext = () => {
  if (swiperInstance.value) {
    swiperInstance.value.slideNext();
  }
};

const goPrev = () => {
  if (swiperInstance.value) {
    swiperInstance.value.slidePrev();
  }
};

const breakpoints = {
  550: {
    slidesPerView: 'auto',
    spaceBetween: 30,
  },
  0: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
};
</script>

<template>
  <swiper
    ref="swiperRef"
    :slides-per-view="'auto'"
    :space-between="50"
    @swiper="onSwiper"
    :breakpoints="breakpoints"
    :modules="[Navigation]"
    v-motion
    :initial="{opacity: 0, x: 30}"
    :visibleOnce="{opacity: 1, x: 0, transition: { duration: 700, delay: 100 }}"
  >
    <swiper-slide v-for="(i, id) in items" :key="id">
      <FeatureItem
        :title="i.title"
        :text="i.text"
        :background="i.background"
        :index="id"
      />
    </swiper-slide>
    <div class="controls flex align-center">
      <SliderControl
        @click="goPrev"
        :disabled="swiperInstance?.isBeginning"
      ></SliderControl>
      <SliderControl
        @click="goNext"
        btnType="next"
        :disabled="swiperInstance?.isEnd"
      ></SliderControl>
    </div>
  </swiper>

</template>

<style lang="scss">
.swiper {
  overflow: clip;
  margin: 0 -10% 0 0;
  padding-right: 80px;
  @include breakpoint(medium) {
    padding-right: 50px;
  }
  @include breakpoint(small) {
    padding-right: 20px;
  }

  &.visible {
    overflow: visible;
  }

  .swiper-slide {
    width: auto !important;
    height: auto;
  }
}
.controls {
  display: flex;
  gap: 20px;
  margin: 40px 0 0 0;
  @include breakpoint(large) {
    margin: 50px 0 0 0;
  }
  @include breakpoint(medium) {
    margin: 40px 0 0 0;
  }
  @include breakpoint(small) {
    margin: 30px 0 0 0;
  }
}

@media (max-width: 768px) {
  .swiper {
    margin: 0;
  }
  .swiper > div:first-child {
    padding-left: 0;
    margin-right: 0;
  }
  //.swiper .swiper-slide {
  //  width: calc(100vw - 40px) !important;
  //  margin-right: 30px !important;
  //}
  .controls {
    margin: 40px 0 0;
  }
}

@media (max-width: 550px) {
  .swiper {
    margin: 0 -10% 0 0;
  }
  .swiper .swiper-slide {
    width: calc(100vw - 40px) !important;
    margin-right: 9.3vw !important;
  }
}
</style>