<script setup>
import {ref} from "vue";
import { useRouter, useRoute } from 'vue-router';
import Logo from "@/components/base/Logo.vue";
import {useScrollStore} from "@/store/scroll";
import {nav} from "@/components/base/constants";

const navs = ref(nav);
const isOpened = ref(false);
const navStore = useScrollStore();
const activeIndex = ref(-1);
const router = useRouter();
const route = useRoute();

const handleDropdown = (index) => {
  if (index === 3) {
    isOpened.value = !isOpened.value;
  }
};

const setBlock = (index) => {
  if(!!navs.value[index]?.href) {
    if(index === 0) navStore.setBlockIndex('home');
    router.push({path: navs.value[index].href})
  } else {
    if(index !== 4) {
      if(route.path !== '/') router.push('/');
      activeIndex.value = index;
      navStore.setBlockIndex(navs.value[index].block);
      navStore.setBlockClicked(true);
      setTimeout(() => {
        navStore.setBlockClicked(false);
        navStore.setBlockIndex("home");
      }, 500);
    } else if (index === 4) {
      navStore.setBlockIndex('products');
      isOpened.value = !isOpened.value;
    }
  }
}


document.addEventListener('click', function(event) {
  const dropdownToggle = document.querySelector('.toggle');
  const dropdownMenu = document.querySelector('.hidden-nav');

  if (window.innerWidth > 700 && !!dropdownMenu && !!dropdownToggle && !dropdownToggle.contains(event.target) && !dropdownMenu.contains(event.target)) {
    isOpened.value = false;
  }
});
</script>

<template>
  <nav class="flex justify-between align-center">
    <Logo />
    <div class="navs flex">
      <div class="navs__item"
        v-for="(nav, index) in navs"
        :key="index"
        :class="{'active': isOpened && index === 3}"
        @click="index === 3 ? handleDropdown(index) : setBlock(index)"
      >
        <div
          class="navs__label flex"
          :class="{'toggle': index === 3, 'active': isOpened}"
        >
          {{nav.label}}
          <svg v-if="!!nav?.items" xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
            <path d="M10.4982 6.79407C10.7179 6.80034 10.9188 6.88196 11.082 7.05774L15.8532 11.9419C15.9914 12.0801 16.0667 12.2558 16.0667 12.463C16.0667 12.8774 15.7402 13.2101 15.3259 13.2101C15.125 13.2101 14.9304 13.1285 14.786 12.9841L10.5045 8.58327L6.21038 12.9841C6.06599 13.1222 5.87765 13.2101 5.67048 13.2101C5.25614 13.2101 4.92969 12.8774 4.92969 12.463C4.92969 12.2558 5.00502 12.0801 5.14314 11.9419L9.92062 7.05774C10.0901 6.88196 10.2785 6.79407 10.4982 6.79407Z" fill="white"/>
          </svg>
        </div>
        <div v-if="isOpened && index === 3" class="hidden-nav flex flex-col">
          <RouterLink
              v-for="(item, id) in nav?.items"
              :key="id"
              :to="item.href"
          >{{item.label}}</RouterLink>
        </div>
      </div>
    </div>
  </nav>
</template>

<style scoped lang="scss">
nav {
  border-radius: 16px;
  border: 1px solid #3a3a3a;
  background: rgba(0, 0, 0, 0.59);
  width: 100%;
  max-width: 920px;
  padding: 19px 34px;
  height: 90px;
  position: relative;
}
.navs {
  gap: 58px;
  &__label {
    svg {
      transform: rotate(180deg);
      margin-left: 6px;
      transition: all .3s ease;
      path {
        fill-opacity: 0.72;
        transition: all .3s ease;
      }
    }
    &.toggle.active {
      svg {
        transform: rotate(0);
        path {
          fill-opacity: 0.42;
        }
      }
    }
  }
  &__item {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    color: rgba(255, 255, 255, 0.72);
    &:hover {
      color: rgba(255, 255, 255, 0.42);
      svg path {
        fill-opacity: 0.42;
      }
    }
    &:active, &.active {
      color: rgba(255, 255, 255, 0.32);
    }
  }
}
.hidden-nav {
  position: absolute;
  border-radius: 16px;
  border: 1px solid #3a3a3a;
  background: rgba(0, 0, 0, 0.59);
  min-width: 194px;
  overflow: hidden;
  right: 0;
  top: 105%;
  a {
    display: block;
    color: rgba(255, 255, 255, 0.72);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 13px 14px;
    text-decoration: none;
    transition: all .3s ease;
    &:hover {
      background: rgba(255, 255, 255, 0.05);
    }
    &:active {
      color: rgba(255, 255, 255, 0.32);
    }
  }
}

@media (max-width: 1024px) {
  nav {
    width: 95%;
    padding: 19px 24px;
  }
  .navs {
    gap: 30px;
  }
}

@media (max-width: 768px) {
  nav {
    display: none;
  }
}
</style>