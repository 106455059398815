<script setup>
import Button from "@/components/ui/Button.vue";

const emit = defineEmits(['close']);

const props = defineProps({
  isSuccess: Boolean
})

const getContinueOrHide = (val) => {
  if(val === 'hide') {
    emit('close', 'hide');
  } else {
    emit('close', 'cont');
  }
}

</script>

<template>
  <div class="response-modal">
    <svg @click="$emit('close', 'hide')" class="absolute" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M6 18L18 6M6 6L18 18" stroke="white" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <div class="block flex flex-col align-center">
      <p class="title">{{ isSuccess ? 'Success' : 'Error' }}</p>
      <div class="flex flex-col align-center">
        <img :src="require(`@/assets/img/${isSuccess ? 'success.png' : 'error.png'}`)" alt="dragomine response modal icon" />
        <p v-if="isSuccess" class="white text2">Your application has been accepted. <br/>You will be contacted shortly for further details.</p>
        <p v-else class="white text2">Something went wrong. <br/>Please try again!</p>
      </div>
      <div class="flex buttons">
        <template v-if="isSuccess">
          <Button class="green" outline gradient @click="getContinueOrHide('hide')">continue</Button>
        </template>
        <template v-else>
          <Button outline gradient @click="getContinueOrHide">try again</Button>
        </template>
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">
.response-modal {
  position: relative;
  color: $white;
  padding: 40px;
  max-width: 500px;
  width: 60vw;
  border-radius: 33px;
  border: 1px solid rgba(217, 217, 217, 0.10);
  background: rgba(255, 255, 255, 0.03);
  button {
    max-width: none !important;
  }
  .title {
    color: $white;
    font-size: 43px;
    font-weight: 500;
    line-height: 49px;
  }
  svg {
    position: absolute;
    right: 24px;
    top: 24px;
    cursor: pointer;
    path {
      transition: all .3s ease;
    }
    &:hover {
      path {
        stroke-opacity: 1;
      }
    }
  }
  img {
    width: 152px;
    height: 165px;
  }

  .block {
    gap: 18px;

    & > .flex-col {
      gap: 8px;
      text-align: center;
      max-width: 420px;
      width: 100%;
    }

    .buttons, button {
      gap: 8px;
      width: 100%;

    }
  }
}

@media (max-width: 700px) {
  .response-modal {
    padding: 32px;
    width: calc(100vw - 40px);
    .title {
      font-size: 34px;
      line-height: 44px;
    }
    img {
      width: 138px;
      height: 149px;
    }
    .block {
      .buttons {
        flex-direction: column-reverse;
      }
    }
  }
}

</style>