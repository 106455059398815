import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/marketing-strategy',
    name: 'marketing-strategy',
    component: function () {
      return import('../views/MarketingStrategyView.vue')
    }
  },
  {
    path: '/marketing',
    name: 'marketing',
    component: function () {
      return import('../views/MarketingView.vue')
    }
  },
  {
    path: '/creative-development',
    name: 'creative-development',
    component: function () {
      return import('../views/CreativeDevelopmentView.vue')
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: function () {
      return import('../views/PrivacyView.vue')
    }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.afterEach((to, from) => {
  window.scrollTo(0, 0);
})

export default router
