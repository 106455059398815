<script setup>
import { ref, watch, onUnmounted } from "vue";
import Input from "@/components/ui/Input.vue";
import Button from "@/components/ui/Button.vue";

const emit = defineEmits(['response']);

const props = defineProps({
  inModal: Boolean
})

const data = ref({
  name: '',
  email: '',
  message: '',
  subject: '',
});

const isChecked = ref(false);
const isError = ref(false);
const emailRegex = ref(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

const isSent = ref(false);
const isSuccess = ref(false);

watch(isSent, () => {
  if(!isSent.value) resetForm();
});

const validate = () => {
  let error = false;
  Object.entries(data.value).map(([key, value]) => {
    if(key === 'name' && value.toString().trim().length == 0) error = true;
    if(key === 'email' && !emailRegex.value.test(value)) error = true;
  });
  return error;
}

const handleInput = (event,propertyName) => {
  data.value[propertyName] = event.target.value;
  if(isError.value) validate();
}

const resetForm = () => {
  data.value = {
    name: "",
    email: "",
    subject: "",
    message: "",
  }
  isSent.value = false;
  isChecked.value = false;
}

const fetchData = (formdata) => {
  const request = {
    method: 'POST',
    body: formdata,
    mode: 'no-cors',
  }

  fetch('https://license.darmius.kz/mailsend', request)
    .then(response => {
      isSent.value = true;
      isSuccess.value = true;
      emit('response', true);
      resetForm();
    })
    .catch(e => {
      isSent.value = true;
      isSuccess.value = false;
      emit('response', false);
    })
}

const send = () => {
  isError.value = validate();
  if(!isError.value) {
    const formdata = new FormData();
    formdata.append('id', 'Dragomine Website');
    formdata.append('name', 'User name: ' + data.value.name);
    formdata.append('email', 'User email: ' + data.value.email);
    formdata.append('emailto', "info@dragomine.agency");
    formdata.append('message', 'Message: ' + data.value.message);
    formdata.append('subject', 'Dragomine Website Request: ' + data.value.subject);

    fetchData(formdata)
  }
}

onUnmounted(() =>{
  resetForm();
});
</script>

<template>
  <div class="f-form">
    <div class="f-form__text flex flex-col" :class="{'second align-center': inModal, 'gradient-text horizontal': !inModal}">
      <span class="title">Seeking personalized support?</span>
      <span class="subtitle" :class="{'gradient-text higher': inModal }">Send a mail to our team!</span>
    </div>

    <form class="flex flex-col" :class="{'second': inModal}" id="formm" @submit.prevent="send">
      <div class="flex inputs" :class="{'flex-col': !inModal}">
        <Input
          :uppercase="!inModal"
          :placeholder="inModal ? 'Your name' : 'Name*'"
          type="text"
          v-model="data.name"
          :isError="isError && data.name.toString().trim().length == 0"
          required
          v-motion
          :initial="inModal ? {} : {opacity: 0, x: -10}"
          :visibleOnce="{opacity: 1, x: 0, transition: { duration: 500, delay: 100 }}"
        />
        <Input
          :uppercase="!inModal"
          :placeholder="inModal ? 'Your email' : 'Email*'"
          type="email"
          v-model="data.email"
          required
          :isError="isError && !emailRegex.test(data.email)"
          v-motion
          :initial="inModal ? {} : {opacity: 0, x: -10}"
          :visibleOnce="{opacity: 1, x: 0, transition: { duration: 500, delay: 150 }}"
        />
      </div>
      <Input
        :uppercase="!inModal"
        :placeholder="inModal ? 'Subject' : 'Subject'"
        type="text"
        v-model="data.subject"
        v-motion
        :initial="inModal ? {} : {opacity: 0, x: -10}"
        :visibleOnce="{opacity: 1, x: 0, transition: { duration: 500, delay: 200 }}"
      />
      <Input
        :uppercase="!inModal"
        :placeholder="inModal ? 'Your message' : 'Message'"
        type="text"
        textarea
        :high="inModal"
        v-model="data.message"
        v-motion
        :initial="inModal ? {} : {opacity: 0, x: -10}"
        :visibleOnce="{opacity: 1, x: 0, transition: { duration: 500, delay: 250 }}"
      />
      <div class="flex bottom" :class="{'flex-col border': !inModal}">
        <label class="custom-checkbox flex align-center" :class="{'checked': isChecked}" v-motion
               :initial="inModal ? {} : {opacity: 0, x: -10}"
               :visibleOnce="{opacity: 1, x: 0, transition: { duration: 600, delay: 250 }}">
          <input type="checkbox" id="ch1" name="check" v-model="isChecked" required/>
          <span class="checkmark"></span>
          <span class="check-text transparency-text second" :class="{'little': inModal}">
            Please read
            <RouterLink class="no-underline" to="/privacy">Privacy Policy</RouterLink>
            before you send us your feedback.
          </span>
        </label>
        <Button
            type="submit"
            v-motion
            :initial="inModal ? {} : {opacity: 0, x: -10}"
            :visibleOnce="{opacity: 1, x: 0, transition: { duration: 500, delay: 250 }}"
        >Submit feedback</Button>
      </div>
    </form>
  </div>
</template>

<style scoped lang="scss">
.f-form {
  &__text.second {
    .title {
      color: rgba(255, 255, 255, 0.50);
      font-size: 26px;
      font-weight: 500;
      line-height: 36px;
    }
    .subtitle {
      font-size: 44px;
      font-weight: 500;
      line-height: 66px;
    }
  }
  span, p {
    font-size: 22px;
    font-weight: 500;
    line-height: 32px;
  }
  form {
    gap: 12px;
    margin-top: 36px;
    .bottom {
      margin-top: 24px;
      &.border {
        margin-top: 20px;
      }
    }
    &.second {
      margin: 60px 0 0;
      width: 100%;
      .f-form__text {
        text-align: center;
      }
      button {
        max-width: 238px;
        margin-top: 0;
      }
      .check-text {
        max-width: 288px;
      }
      .custom-checkbox {
        align-items: flex-start;
      }
      .bottom {
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;
      }
    }
    .inputs {
      gap: 12px;
    }
    button {
      margin-top: 24px;
    }
  }
  .border {
    padding-top: 22px;
    border-top: 1px solid rgba(210, 210, 215, 0.34);
  }
}
.custom-checkbox {
  position: relative;
  cursor: pointer;
  align-items: flex-start !important;
  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
  }
  input[type="checkbox"]:checked + .checkmark {
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-size: 50% 50%;
    border-radius: 6px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("@/assets/icons/check.svg");
  }
  &:hover {
    input[type="checkbox"]:checked + .checkmark {
      border: 1px solid $white;
      & + .check-text {
        color: $white !important;
      }
    }
    .checkmark {
      border: 1px solid $white-40;
    }
    .check-text {
      color: rgba(217, 217, 217, 0.90) !important;
    }
  }

  .check-text {
    font-size: 16px;
    line-height: 24px;
    margin-left: 8px;
    transition: all .3s ease;
    a {
      color: $blue;
      text-decoration: none;
    }
    &.little {
      color: rgba(255, 255, 255, 0.50);
      font-size: 14px;
      line-height: 20px;
    }
  }
  .checkmark {
    display: inline-block;
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    border-radius: 6px;
    border: 1px solid $white-20;
    background: none;
    cursor: pointer;
    transition: border .3s ease;
  }
}
</style>