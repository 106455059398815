<script setup>
import { ref, onUnmounted, watch } from "vue";
import Form from "@/components/ui/Form.vue";
import ResponseModal from "@/components/ui/ResponseModal.vue";

const emit = defineEmits(['close']);

const props = defineProps({
  isOpen: Boolean
})

const isSuccess = ref(false);
const isSent = ref(false);

watch(() => props.isOpen, () => {
  if(props.isOpen) document.body.classList.add('no-scroll');
})

const getResponse = (val) => {
  isSent.value = true;
  isSuccess.value = val;
}

const closeResponseModal = (val) => {
  if(val === 'hide') {
    emit('close');
    isSent.value = false;
  } else {
    isSent.value = false;
  }
}
onUnmounted(() => {
  isSent.value = false;
  document.body.classList.remove('no-scroll');
})
</script>

<template>
  <Transition name="modal">
    <div v-if="isOpen" class="modal-wrapper flex align-center justify-center">
      <template v-if="!isSent">
        <div class="background"></div>
        <div class="close" @click="$emit('close')">
          <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
            <path d="M0.458055 20.2324C-0.140783 20.8312 -0.169299 21.9004 0.472313 22.5277C1.09967 23.155 2.16902 23.1408 2.76786 22.542L11.4938 13.8025L20.2339 22.542C20.847 23.155 21.9021 23.155 22.5295 22.5277C23.1426 21.8862 23.1568 20.8454 22.5295 20.2324L13.8036 11.4929L22.5295 2.76764C23.1568 2.15459 23.1568 1.09958 22.5295 0.472276C21.8879 -0.140772 20.847 -0.155029 20.2339 0.458019L11.4938 9.19751L2.76786 0.458019C2.16902 -0.140772 1.08541 -0.169285 0.472313 0.472276C-0.155041 1.09958 -0.140783 2.16885 0.458055 2.76764L9.19823 11.4929L0.458055 20.2324Z" fill="white" fill-opacity="0.5"/>
          </svg>
        </div>
        <div class="modal">
          <Form inModal @response="getResponse"/>
        </div>
      </template>
      <ResponseModal v-else :isSuccess="isSuccess" @close="closeResponseModal"/>
    </div>
  </Transition>
</template>

<style lang="scss">
.modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  background: url("@/assets/img/background.jpg") no-repeat center/cover;
  padding: 0 40px;
  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    z-index: -1;
  }
  .close {
    position: absolute;
    right: 40px;
    top: 40px;
    cursor: pointer;
    svg path {
      transition: all .3s ease;
    }
    &:hover {
      svg path {
        fill-opacity: 1;
      }
    }
  }
  .modal {
    max-width: 662px;
    width: 100%;
  }
}

.modal-enter-active,
.modal-leave-active {
  transition: transform 0.5s ease;
}

.modal-enter-from,
.modal-leave-to {
  transform: translateY(100%);
}

.modal-enter-to,
.modal-leave-from {
  transform: translateY(0);
}

@media (max-width: 768px) {
  .modal-wrapper {
    padding: 0 20px;
    .background {
      background: rgba(0,0,0,0.3);
    }
    .close {
      right: 20px;
      top: 20px;
    }
    .f-form {
      &__text.second {
        .title {
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          text-align: center;
        }
        .subtitle {
          font-size: 22px;
          font-weight: 700;
          line-height: 36px;
          margin-top: 7px;
          text-align: center;
        }
      }
      form {
        gap: 12px;
        .inputs {
          gap: 12px !important;
        }
        button {
          width: 100%;
        }
        &.second {
          margin: 32px 0 0;
          .custom-checkbox {
            width: 100%;
          }
          .check-text {
            max-width: none;
          }

          .bottom {
            margin-top: 0;
            flex-direction: column;
            gap: 28px;
            button {
              max-width: none;
              height: 70px;
            }
          }
        }
        .inputs {
          flex-direction: column;
          gap: 20px;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .modal-wrapper {
    .modal {
      height: 80%;
      overflow-y: auto;
    }
  }
}
</style>