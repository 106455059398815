<script setup>
import { ref, watch, onMounted, onUnmounted } from "vue";
import { onClickOutside } from '@vueuse/core';
import { useRouter } from 'vue-router';
import Logo from "@/components/base/Logo.vue";
import { useScrollStore } from "@/store/scroll";
import {nav} from "./constants";

const emit = defineEmits(['close']);

const props = defineProps({
  isOpen: Boolean
})

const navs = ref(nav);
const scrollStore = useScrollStore();
const router = useRouter();

const isDropdown = ref(false);

const target = ref(null);

onMounted(() => {
  onClickOutside(target.value, event => {
    if(window.innerWidth > 768) {
      if(isDropdown.value) isDropdown.value = false;
    }
  })
})

onUnmounted(() => {
  document.body.classList.remove("no-scroll");
})

const handleClick = (index, block) => {
  if(index === 3) {
    if(window.innerWidth <= 768) {
      isDropdown.value = !isDropdown.value;
    } else {
      isDropdown.value = true;
    }
  } else {
    document.body.classList.remove('no-scroll');
    if(router.currentRoute.value.name !== 'home') router.push('/');
    scrollStore.setBlockClicked(true);
    scrollStore.setBlockIndex(block);
    emit('close');
    setTimeout(() => {
      scrollStore.setBlockClicked(false);
      scrollStore.setBlockIndex("");
    }, 500);
  }
}
</script>

<template>
  <Transition name="mob-menu">
    <div v-if="isOpen" class="menu flex flex-col">
      <div class="bg" />
      <div class="menu__header flex align-center justify-between">
        <Logo />
        <div class="close" @click="$emit('close')">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M0.957078 20.7324C0.358241 21.3312 0.329725 22.4004 0.971336 23.0277C1.59869 23.655 2.66804 23.6408 3.26688 23.042L11.9928 14.3025L20.733 23.042C21.3461 23.655 22.4012 23.655 23.0285 23.0277C23.6416 22.3862 23.6559 21.3454 23.0285 20.7324L14.3026 11.9929L23.0285 3.26764C23.6559 2.65459 23.6559 1.59958 23.0285 0.972276C22.3869 0.359228 21.3461 0.344971 20.733 0.958019L11.9928 9.69751L3.26688 0.958019C2.66804 0.359228 1.58443 0.330715 0.971336 0.972276C0.343983 1.59958 0.358241 2.66885 0.957078 3.26764L9.69725 11.9929L0.957078 20.7324Z" fill="white" fill-opacity="0.5"/>
          </svg>
        </div>
      </div>
      <div class="wrap">
        <div class="menu__body flex flex-col">
          <div
              class="menu-item"
              :class="{'active': index === 3 && isDropdown}"
              v-for="(nav, index) in navs"
              :key="index"
          >
            <div
                class="menu-item__label flex align-center"
                :class="{'toggle': index === 3}"
                @click="handleClick(index, nav?.block)"
            >
              {{nav.label}}
              <svg v-if="!!nav?.items && nav?.items.length > 0" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                <path d="M22.0002 26.6045C22.3159 26.5955 22.6045 26.4783 22.839 26.2257L29.6938 19.2086C29.8922 19.0102 30.0004 18.7577 30.0004 18.46C30.0004 17.8647 29.5314 17.3867 28.9361 17.3867C28.6475 17.3867 28.3679 17.504 28.1605 17.7114L22.0092 24.034L15.84 17.7114C15.6325 17.513 15.3619 17.3867 15.0643 17.3867C14.469 17.3867 14 17.8647 14 18.46C14 18.7577 14.1082 19.0102 14.3067 19.2086L21.1704 26.2257C21.4139 26.4783 21.6845 26.6045 22.0002 26.6045Z" fill="white" fill-opacity="0.6"/>
              </svg>
            </div>
            <div ref="target" v-if="isDropdown && !!nav?.items && nav?.items.length > 0" class="hidden-menu flex flex-col">
              <RouterLink
                  v-for="(item, id) in nav?.items"
                  :key="id"
                  :to="item.href"
              >{{item.label}}</RouterLink>
            </div>
          </div>
        </div>
      </div>

      <div class="menu__footer flex align-center justify-between">
        <p>© 2024 — Dragomine limited. All Rights Reserved</p>
        <RouterLink to="/privacy">Privacy policy</RouterLink>
      </div>
    </div>
  </Transition>
</template>

<style scoped lang="scss">
.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: url("@/assets/img/background.jpg") no-repeat center/cover;
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    z-index: -1;
  }
  &__header {
    height: 90px;
    padding: 0 20px;
  }
  .wrap {
    margin: auto 0;
    overflow-y: auto;
  }
  &__body {
    padding: 0 20px;
    gap: 32px;
    overflow-y: auto;
    .menu-item {
      &__label {
        color: rgba(255, 255, 255, 0.60);
        font-size: 34px;
        font-weight: 500;
        line-height: 44px;
        transition: all .3s ease;
      }
      svg, svg path {
        transition: all .3s ease;
      }
      &.active {
        .menu-item__label {
          color: $white;
        }
        svg {
          transform: rotate(180deg);
        }
        svg path {
          fill-opacity: 1;
        }
      }
    }
    .hidden-menu {
      margin-top: 43px;
      gap: 16px;
      a {
        color: rgba(255, 255, 255, 0.50);
        font-size: 24px;
        line-height: 44px;
        text-decoration: none;
      }
    }
  }
  &__footer {
    margin-top: auto;
    padding: 0 20px 26px;
    p, a {
      color: $white-40;
      font-size: 10px;
      font-weight: 500;
      line-height: 140%;
      text-decoration: none;
    }
  }
}

.mob-menu-enter-active,
.mob-menu-leave-active {
  transition: transform 0.5s ease;
}

.mob-menu-enter-from,
.mob-menu-leave-to {
  transform: translateX(100%);
}

.mob-menu-enter-to,
.mob-menu-leave-from {
  transform: translateX(0);
}
</style>