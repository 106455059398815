<script setup>
import { ref, onUnmounted } from "vue";
import Form from "@/components/ui/Form.vue";
import ResponseModal from "@/components/ui/ResponseModal.vue";

const isSuccess = ref(false);
const isSent = ref(false);

const getResponse = (val) => {
  isSent.value = true;
  isSuccess.value = val;
}

const closeResponseModal = (val) => {
  if(val === 'hide') {
    isSent.value = false;
  } else {
    isSent.value = false;
  }
}
</script>

<template>
  <section class="feedback">
    <div class="container flex flex-col">
      <h4 class="gradient-text" v-motion :initial="{opacity: 0, scale: 2.2, rotate: -15}" :visibleOnce="{opacity: 1, scale: 1, rotate: 0, transition: { duration: 700, delay: 100 }}">Feedback</h4>
      <div class="flex feedback__content">
        <Form @response="getResponse"/>
        <div class="feedback__img">
          <img src="@/assets/img/feedback.png" alt="Feedback image"/>
        </div>
      </div>
    </div>
    <div v-if="isSent" class="resp flex align-center justify-center">
      <ResponseModal :isSuccess="isSuccess" @close="closeResponseModal"/>
    </div>
  </section>
</template>

<style lang="scss">
.feedback {
  padding: 100px 0 120px;
  .container {
    align-items: center;
  }
  &__content {
    width: 100%;
    max-width: 1140px;
    margin: 71px auto 0;
    justify-content: space-between;
    gap: 20px;
    & > div:first-child {
      max-width: 420px;
      width: 100%;
    }
  }
  &__img {
    border-radius: 40px;
    background: $light-text;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .resp {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    background: url("@/assets/img/background.jpg") no-repeat center/cover;
    padding: 0 40px;
  }
}

@media (max-width: 768px) {
  .feedback {
    padding: 59px 0 60px;
    &__content {
      flex-direction: column;
      margin-top: 35px;
    }
    &__img {
      height: 74.6vw;
      max-height: 400px;
    }
    &__img img {
      margin-bottom: -2%;
      object-fit: cover;
    }
    .f-form {
      max-width: none !important;
      p {
        font-size: 26px;
        line-height: 36px;
      }
      .custom-checkbox {
        .check-text {
          font-size: 14px;
          line-height: 28px;
        }
      }
      form {
        margin-top: 24px !important;
      }
      form, .inputs {
        gap: 10px;
      }
      .border {
        border-top: none !important;
        padding-top: 6px;
        margin-top: 0 !important;
      }
    }
  }
}
</style>