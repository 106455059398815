import {ref} from "vue";

export const nav = ref([
  {
    label: "About us",
    block: "about",
  },
  {
    label: "Mission & Vision",
    block: "mission",
  },
  {
    label: "Features",
    block: "features",
  },
  {
    label: "Services",
    items: [
      {
        label: "Marketing strategies",
        href: "marketing-strategy",
      },
      {
        label: "360º Marketing",
        href: "marketing",
      },
      {
        label: "Creative & development",
        href: "creative-development",
      }
    ]
  }
]);