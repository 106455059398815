<script setup>
import {ref, watch} from "vue";
import { useRouter } from "vue-router";
import Modal from "@/components/ui/Modal.vue";
import {useScrollStore} from "@/store/scroll";

const props = defineProps({
  title: String,
  text: String,
  image: String,
  isForm: Boolean,
  links: Array,
  mainLink: String
})

const router = useRouter();
const navStore = useScrollStore();

const redirectToPageBlock = (index) => {
  navStore.setBlockClicked(true);
  navStore.setBlockIndex(props.links[index].block);
  router.push(props.links[index].href);
  setTimeout(() => {
    navStore.setBlockClicked(false);
    navStore.setBlockIndex("home");
  }, 1000);
}

const isModal = ref(false);
const handleClick = () => {
  if(props.isForm) {
    isModal.value = true;
  }
}

watch(() => isModal.value, () => {
  if(!isModal.value) document.body.classList.remove('no-scroll');
})
</script>

<template>
  <div class="service-card" :class="{'isForm': isForm}">
    <div class="inner" :class="{'isDark': isForm}" @click="handleClick">
      <div class="service-card__body flex flex-col align-center">
        <p class="service-card__title" v-motion :initial="{opacity: 0, y: -15}" :visibleOnce="{opacity: 1, y: 0, transition: { duration: 700, delay: 100 }}">{{ title }}</p>
        <p class="service-card__text" v-motion :initial="{opacity: 0, y: -15}" :visibleOnce="{opacity: 1, y: 0, transition: { duration: 700, delay: 300 }}">{{ text }}</p>
        <RouterLink v-if="!isForm" :to="mainLink">
          <svg class="service-card__arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102 102" fill="none">
            <circle cx="51" cy="51" r="50.5" stroke="#D2D2D7"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M68 51L52.9929 66L50.8791 63.8872L56.8521 57.9171C58.8539 55.9162 57.4368 52.494 54.6065 52.494L34 52.494L34 49.506L62.2778 49.506L50.8791 38.1128L52.9929 36L68 51Z" fill="#D2D2D7"/>
          </svg>
        </RouterLink>
        <svg v-else class="service-card__arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102 102" fill="none">
          <circle cx="51" cy="51" r="50.5" stroke="#D2D2D7"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M68 51L52.9929 66L50.8791 63.8872L56.8521 57.9171C58.8539 55.9162 57.4368 52.494 54.6065 52.494L34 52.494L34 49.506L62.2778 49.506L50.8791 38.1128L52.9929 36L68 51Z" fill="#D2D2D7"/>
        </svg>
      </div>

      <div class="service-card__img">
        <img :src="require(`@/assets/img/${image}`)" alt="block illustration"/>
      </div>
    </div>
    <div class="outer flex flex-col align-center justify-center" :class="{'isLight': isForm}">
      <span v-for="(link, i) in links" :key="i" @click="redirectToPageBlock(i)">{{ link.label }}</span>
      <RouterLink v-if="!isForm" :to="mainLink">
        <svg class="service-card__arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102 102" fill="none">
          <circle cx="51" cy="51" r="50.5" stroke="#D2D2D7"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M68 51L52.9929 66L50.8791 63.8872L56.8521 57.9171C58.8539 55.9162 57.4368 52.494 54.6065 52.494L34 52.494L34 49.506L62.2778 49.506L50.8791 38.1128L52.9929 36L68 51Z" fill="#D2D2D7"/>
        </svg>
      </RouterLink>
      <svg v-else class="service-card__arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102 102" fill="none">
        <circle cx="51" cy="51" r="50.5" stroke="#D2D2D7"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M68 51L52.9929 66L50.8791 63.8872L56.8521 57.9171C58.8539 55.9162 57.4368 52.494 54.6065 52.494L34 52.494L34 49.506L62.2778 49.506L50.8791 38.1128L52.9929 36L68 51Z" fill="#D2D2D7"/>
      </svg>
    </div>
    <Modal :isOpen="isModal" @close="isModal = false"/>
  </div>
</template>

<style scoped lang="scss">
.service-card {
  width: 49%;
  max-width: 702px;
  position: relative;
  cursor: pointer;
  .inner {
    background: #F5F5F7;
    position: relative;
    overflow: hidden;
    transition: all .3s ease;
    height: 100%;
    &.isDark {
      background: rgba(245, 245, 247, 0.16);
      .service-card__title {
        color: $light-text;
      }
      .service-card__text {
        color: rgba(255, 255, 255, 0.86);
      }
      svg {
        circle, path {
          transition: all .2s ease;
        }
        &:hover {
          circle {
            fill: #1D1D1F;
            stroke: #1D1D1F;
          }
          path {
            fill: #F5F5F7;
          }
        }
        &:active {
          opacity: 0.9;
        }
      }
    }
  }
  .outer {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: all .3s ease;
    span {
      display: block;
      color: rgba(245, 245, 247, 0.39);
      text-align: center;
      font-size: 28px;
      font-weight: 500;
      line-height: 34px;
      text-decoration: none;
      transition: all .3s ease;
      cursor: pointer;
      & + span {
        margin-top: 22px;
      }
      &:hover {
        color: $light-text;
      }
    }
    a {
      margin: 22px auto 0;
      display: block;
    }
    svg {
      circle, path {
        transition: all .3s ease;
      }
      &:hover {
        circle {
          stroke: #F5F5F7;
          fill: #F5F5F7;
        }
        path {
          fill: #1D1D1F;
        }
      }
      &:active {
        path {
          fill: #4A4A4F;
        }
      }
    }
  }
  &__img {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 0;
    img {
      margin-bottom: -10px;
      width: 100%;
    }
  }
  &__arrow {
    font-size: 102px;
    width: 1em;
    height: 1em;
  }
  p {
    color: $dark-text;
    text-align: center;
    transition: all .2s ease;
  }
  svg {
    display: block;
  }
  &__title {
    font-size: 37px;
    font-weight: 700;
    line-height: 44px;
  }
  &__text {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    width: 70%;
    margin: 18px 0 28px;
  }
  &__body {
    position: relative;
    z-index: 4;
    padding: 60px 0 270px;
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .service-card {
    &__img {
      top: auto;
      bottom: -10%;
    }
  }
}

@media (min-width: 769px) {
  .service-card {
    &:not(&.isForm):hover {
      .service-card__body {
        p, svg {
          opacity: 0 !important;
        }
      }
      .outer {
        display: flex;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) -47.33%, rgba(0, 0, 0, 0.91) 107.33%);
        z-index: 7;
      }
      .inner {
        background: #1B1B1B;
      }
    }
    &.isForm:hover {
      .inner {
        background: $light-text;
        p {
          color: $dark-text;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .service-card {
    width: 100%;
    max-width: none;
    max-height: 520px;
    overflow: hidden;
    .inner {
      max-height: 520px;
    }
    &__body {
      padding: 43px 23px 50%;
    }
    &__title {
      font-size: 33px;
      line-height: 38px;
    }
    &__text {
      font-size: 14px;
      line-height: 28px;
      margin: 14px 0 18px;
      width: 100%;
    }
    &__arrow {
      font-size: 72px;
    }
    &__img {
      width: 100%;
      max-height: 230px;
      max-width: 450px;
      left: 50%;
      bottom: -5%;
      transform: translateX(-50%);
      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>