<script setup>
import { ref } from "vue";
import ServiceCard from "@/components/additional/ServiceCard.vue";

const items = ref([
  {
    title: "Marketing Strategies",
    text: "A nutshell, a marketing strategy is a well-defined plan to reach specific marketing goals.",
    image: "service1.png",
    isForm: false,
    mainLink: "/marketing-strategy",
    links: [
      {
        label: "Branding",
        href: "/marketing-strategy",
        block: "branding"
      },
      {
        label: "Digital Customer Experience",
        href: "/marketing-strategy",
        block: "digital"
      },
      {
        label: "Sales Enablement",
        href: "/marketing-strategy",
        block: "sales"
      },
      {
        label: "Inbound Marketing",
        href: "/marketing-strategy",
        block: "inbound"
      }
    ]
  },
  {
    title: "360º Marketing",
    text: "360° marketing is not just a buzzword; it's a necessity in today's competitive landscape.",
    image: "service2.png",
    isForm: false,
    mainLink: "/marketing",
    links: [
      {
        label: "Search (SEO)",
        href: "/marketing",
        block: "seo"
      },
      {
        label: "Content marketing",
        href: "/marketing",
        block: "content"
      },
      {
        label: "Social media marketing",
        href: "/marketing",
        block: "social"
      },
      {
        label: "Email marketing",
        href: "/marketing",
        block: "email"
      },
      {
        label: "Digital PR",
        href: "/marketing",
        block: "pr"
      },
      {
        label: "Conversion rate optimization (CRO)",
        href: "/marketing",
        block: "cro"
      }
    ]
  },
  {
    title: "Creative & Development",
    text: "It's where imagination meets execution, the spark ignites the structure.",
    image: "service3.png",
    isForm: false,
    mainLink: "/creative-development",
    links: [
      {
        label: "Web Development",
        href: "/creative-development",
        block: "web"
      },
      {
        label: "Design Thinking",
        href: "/creative-development",
        block: "design"
      },
      {
        label: "User experience",
        href: "/creative-development",
        block: "ux"
      },
      {
        label: "Mobile & Web apps",
        href: "/creative-development",
        block: "mobile"
      },
      {
        label: "Communities",
        href: "/creative-development",
        block: "comm"
      }
    ]
  },
  {
    title: "Get in touch",
    text: "Often the easiest way to send a message directly through a website.",
    image: "service4.png",
    isForm: true,
    mainLink: "/",
    links: []
  }
])
</script>

<template>
  <section class="services flex flex-col align-center" ref="services">
    <h4 class="gradient-text" v-motion :initial="{opacity: 0, scale: 2.2, rotate: -15}" :visibleOnce="{opacity: 1, scale: 1, rotate: 0, transition: { duration: 700, delay: 100 }}">Services</h4>
    <div class="services__content flex flex-wrap justify-center">
      <ServiceCard
        v-for="(service, index) in items"
        :key="index"
        :title="service.title"
        :image="service.image"
        :text="service.text"
        :isForm="service.isForm"
        :mainLink="service.mainLink"
        :links="service.links"
      />
    </div>
  </section>
</template>

<style scoped lang="scss">
.services {
  h4 {
    text-align: center;
  }
  &__content {
    gap: 12px;
    margin: 40px auto 0;
    max-width: 1416px;
  }
}

@media (max-width: 768px) {
  .services {
    &__content {
      flex-direction: column;
      margin: 29px 0 0;
      width: 100%;
      gap: 14px;
    }
  }
}
</style>