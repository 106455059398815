<script setup>
import { computed } from "vue";
const props = defineProps({
  title: String,
  text: String,
  index: Number,
  background: String
})
const gradientStyle = computed(() => ({
  background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.80) 100%)',
}));

const imageStyle = computed(() => ({
  backgroundImage: `url(${require('@/assets/img/' + props.background)})`,
}));

const fallbackColor = computed(() => ({
  backgroundColor: '#C8C8C8',
}));
</script>

<template>
  <div class="feature-item flex flex-col" :style="[gradientStyle, imageStyle, fallbackColor]">
    <p class="feature-item__index light-text">0{{ index+1 }}.</p>
    <p class="feature-item__title transparency-text third">{{ title }}</p>
    <p class="transparency-text">{{ text }}</p>
  </div>
</template>

<style scoped lang="scss">
.feature-item {
  position: relative;
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.23);
  max-width: 450px;
  padding: 74px 80px 47px;
  height: 100%;
  background-repeat: no-repeat !important;
  background-size: 103% 103% !important;
  background-position: center center !important;
  &__index {
    font-size: 44px;
    font-weight: 700;
    line-height: 66px;
    margin-bottom: 50px;
  }
  &__title {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
  }
  p:last-child {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .feature-item {
    padding: 50px 53px 53px;
    &__index {
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 33px;
    }
    &__title {
      margin-bottom: 8px;
      font-size: 18px;
      line-height: 24px;
    }
    p:last-child {
      font-size: 14px;
      line-height: 22px;
      opacity: 0.7;
    }
  }
}

@media (max-width: 550px) {
  .feature-item {
    max-width: none;
  }
}
</style>