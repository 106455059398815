<script setup>
import {ref, watch} from "vue";
import Nav from "@/components/base/Nav.vue";
import Button from "@/components/ui/Button.vue";
import Modal from "@/components/ui/Modal.vue";
import MobileHeader from "@/components/base/MobileHeader.vue";

const props = defineProps({
  text: String,
  withBtn: Boolean
})

const isModal = ref(false);

watch(() => isModal.value, () => {
  if(!isModal.value) document.body.classList.remove('no-scroll');
})
</script>

<template>
  <section class="banner">
    <video id="vid" playsinline="" defaultmuted="" autoplay="" muted="" loop="">
      <source width="100%" src="@/assets/video/banner.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
    <div class="overlay"/>
    <div class="banner__content flex flex-col justify-between align-center">
      <Nav v-motion :initial="{opacity: 0}" :visibleOnce="{opacity: 1, transition: { duration: 1000}}"/>
      <MobileHeader />
      <div class="body flex flex-col align-center">
        <p class="banner__title light-text">
          <span class="key" v-motion :initial="{opacity: 0, scale: 1.8, rotate: -15}" :visibleOnce="{opacity: 1, scale: 1, rotate: 0, transition: { duration: 900 }}"><slot name="key"></slot></span>
          <span v-motion :initial="{opacity: 0, y: -10, rotate: -1}" :visibleOnce="{opacity: 1, y: 0, rotate: 0, transition: { duration: 600, delay: 300 }}"><slot name="row"></slot></span>
          <br/>
          <span v-motion :initial="{opacity: 0, y: -5}" :visibleOnce="{opacity: 1, y: 0, transition: { duration: 900, delay: 300 }}"><slot name="other"></slot></span>
        </p>
        <p v-if="text !== ''" class="banner__text" v-motion :initial="{opacity: 0, y: -10}" :visibleOnce="{opacity: 1, y: 0, transition: { duration: 800, delay: 600 }}">{{text}}</p>
        <Button v-if="withBtn" @click="isModal = true" v-motion :initial="{opacity: 0}" :visibleOnce="{opacity: 1, transition: { duration: 500, delay: 900 }}">Get in touch</Button>
      </div>
      <div class="scroll-down flex">
        Scroll down
        <svg class="arrow arrow-first" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M10.0001 13.2059C10.2199 13.1997 10.4208 13.118 10.584 12.9423L15.3552 8.05805C15.4933 7.91994 15.5686 7.74416 15.5686 7.53699C15.5686 7.12265 15.2422 6.78992 14.8278 6.78992C14.627 6.78992 14.4323 6.87153 14.2879 7.01592L10.0064 11.4167L5.71233 7.01592C5.56794 6.87781 5.3796 6.78992 5.17243 6.78992C4.75809 6.78992 4.43164 7.12265 4.43164 7.53699C4.43164 7.74416 4.50698 7.91994 4.64509 8.05805L9.42257 12.9423C9.59208 13.118 9.78041 13.2059 10.0001 13.2059Z" fill="#F5F5F7" fill-opacity="0.4"/>
        </svg>
      </div>
    </div>

    <Modal :isOpen="isModal" @close="isModal = false" />
  </section>
</template>

<style lang="scss">
.banner {
  height: 100vh;
  video {
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    object-fit: cover;
  }
  .overlay {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: radial-gradient(45.62% 51.67% at 50% 50%, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.75) 100%);
  }
  &__content {
    position: relative;
    z-index: 3;
    height: 100%;
    padding: 34px 0 67px;
    header {
      display: none;
    }
    button {
      max-width: 224px;
      margin-top: 10px;
    }
    .body {
      gap: 24px;
    }
  }
  &__title {
    font-size: 66px;
    font-weight: 500;
    line-height: 74px;
    width: 100%;
    text-align: center;
    span {
      display: inline-block;
    }
    span.key {
      background: linear-gradient(119deg, #F5F5F7 32.17%, #000 101.39%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-right: 15px;
    }
  }
  &__text {
    color: rgba(255, 255, 255, 0.60);
    text-align: center;
    line-height: 32px;
    max-width: 372px;
  }
}
.scroll-down {
  gap: 8px;
  color: rgba(245, 245, 247, 0.40);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  width: 107px;
  position: relative;
}

.arrow {
  display: block;
  position: absolute;
  right: 0;
  top: 70%;
  height: 20px;
  transform-origin: 50% 50%;
  transform: translate3d(0%, -50%, 0);
}

.arrow-first {
  animation: arrow-movement 1.6s ease-in-out infinite;
}

.arrow:before {
  transform: rotate(45deg) translateX(-23%);
  transform-origin: top left;
}

.arrow:after {
  transform: rotate(-45deg) translateX(23%);
  transform-origin: top right;
}

@keyframes arrow-movement {
  0% {
    opacity: 0;
    top: 35%;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .banner {
    video {
      height: 103vh;
    }
    .overlay {
      height: 103vh;
    }
    button {
      font-size: 16px;
      width: 73%;
    }
    &__title {
      font-size: 43px;
      line-height: 49px;
    }
    &__text {
      font-size: 14px;
      font-weight: 500;
      line-height: 30px;
      max-width: 310px;
    }
    .body {
      width: 100%;
      padding: 0 20px;
      button {
        height: 76px;
        max-width: 350px;
        margin-top: 26px;
      }
    }
    &__content {
      padding: 0 0 34px;
      header {
        display: flex;
      }
    }
  }
}
</style>